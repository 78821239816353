<template>
    <uw-content title="考核模板" padding="10px">

        <!-- 菜单 -->
        <template slot="suffix">
            <el-button type="primary" size="mini" @click="$refs.templateCreate.Open()">新建模板</el-button>
        </template>

        <!-- 表格 -->
        <uw-table>
            
            <!-- 检索 -->
            <template slot="search">
                <el-input v-model="search.name" size="mini" placeholder="模板名称" />
            </template>

            <!-- 按钮 -->
            <template slot="button">
                <el-button type="primary" size="mini" @click="$refs.xTable.print()" >打印</el-button>
                <el-button type="primary" size="mini" @click="$refs.xTable.openExport()" >导出</el-button>
            </template>

            <!-- 表数据 -->
            <template slot="data">
                <!-- 表内容 -->
                <vxe-table
                    ref="xTable"
                    :data="table"
                    :loading="loading"
                    :seq-config="{startIndex: page.size * (page.current - 1)}"
                    height="100%">

                    <!-- 考核年度 -->
                    <vxe-column min-width="180" field="name" title="模板名称">
                        <template #default="{ row }">
                            <span class="el-span-primary" @click="$refs.templateDrawer.Open(row)">{{ row.name }}</span>
                        </template>
                    </vxe-column>

                    <!-- 考核维度 -->
                    <vxe-column min-width="80" title="考核维度">
                        <template #default="{ row }">
                            {{ row.class.length }}
                        </template>
                    </vxe-column>

                    <!-- 考核指标 -->
                    <vxe-column min-width="80" title="考核子项">
                        <template #default="{ row }">
                            {{  row.class.flatMap(value => value.children).length  }}
                        </template>
                    </vxe-column>

                    <!-- 考评流程 -->
                    <vxe-column min-width="80" title="考评流程">
                        <template #default="{ row }">
                            {{ row.flow.length }}
                        </template>
                    </vxe-column>

                    <!-- 创建人 -->
                    <vxe-column min-width="120" title="创建人">
                        <template #default="{ row }">
                            <div style="display: flex; align-items: center; justify-content: flex-start;">
                                <el-avatar style="margin-right: 5px;" :size="26" :src="row.created_users.avatar"> {{ row.created_users.name[0] }} </el-avatar>
                                {{  row.created_users.name }}
                            </div>
                        </template>
                    </vxe-column>

                    <!-- 更新人 -->
                    <vxe-column min-width="120" title="更新人">
                        <template #default="{ row }">
                            <div style="display: flex; align-items: center; justify-content: flex-start;">
                                <el-avatar style="margin-right: 5px;" :size="26" :src="row.updated_users.avatar"> {{ row.updated_users.name[0] }} </el-avatar>
                                {{  row.updated_users.name }}
                            </div>
                        </template>
                    </vxe-column>

                    <vxe-column min-width="160" field="created_at" title="创建时间" ></vxe-column>
                    <vxe-column min-width="160" field="updated_at" title="更新时间" ></vxe-column>

                    <!-- 操作 -->
                    <vxe-column width="100"  title="操作" align="center" fixed="right">
                        <template #default="{ row }">
                            <el-link @click.native.stop="$refs.templateUpdate.Open(row.id)" type="primary" class="w-margin-r-10">修改</el-link>
                            <el-link @click.native.stop="TableDelete(row.id)" type="danger">移除</el-link>
                        </template>
                    </vxe-column>

                </vxe-table>
            </template>

            <!-- 分页 -->
            <template slot="pager">
                <vxe-pager :total="page.total" :page-size.sync="page.size" :current-page.sync="page.current" @page-change="TableSearch()"></vxe-pager>
            </template>
            
        </uw-table>

        <!-- 组件 -->
        <hrm-kpi-template-create ref="templateCreate" @onChange="TableSearch()" />
        <hrm-kpi-template-update ref="templateUpdate" @onChange="TableSearch()" />
        <hrm-kpi-template-drawer ref="templateDrawer" @onChange="TableSearch()" />
        
    </uw-content>
</template>

<script>
export default {
    data () {
        return {
            // 数据
            table: [],

            // 分页
            page: {
                total: 0,
                size: 50,
                current: 1,
            },

            // 检索
            search: {
                type: null,
                users_id: null
            },

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            },
        }
    },

    computed: {
        loading () {
            return !!this.$store.state.httpRequestCount
        }
    },

    mounted () {
        this.TableSearch()
    },

    methods: {

        // 查询
        TableSearch()
        {
            this.$http.post(
                '/9api/hrm/kpi-template/search',
                {
                    page: this.page,
                    search: this.search,
                    order: this.order,
                    append: ['created_users', 'updated_users']
                }
            ).then((rps) => {

                // 更新数据
                this.page.total = rps.data.total
                this.table = rps.data.body

                // 页码溢出
                if (this.table.length == 0 && this.page.current != 1) {
                    this.page.current = 1
                    this.TableSearch()
                }
            })
        },

        // 移除
        TableDelete(id)
        {
            this.$http.post('/9api/hrm/kpi-template/delete', { id: id }).then(rps => {
                this.TableSearch()
            })
        }
    }
}
</script>